import { Dispatch } from 'redux';

import { mapTranslations } from 'app/map-translations';
import { isExists } from 'utils/is-empty';

export function updateTranslation(translation: { key: string; value: string }, language: string) {
  return {
    type: 'UPDATE_TRANSLATION' as const,
    translation,
    language,
  };
}

function doAddTranslations(translations: {
  [x: number]: {
    keys: Record<string, string>;
    keysWithPreviewId: Record<string, { value: string }>;
  };
}): {
  type: 'ADD_TRANSLATION';
  translations: {
    [x: number]: {
      keys: Record<string, string>;
      keysWithPreviewId: Record<string, { value: string }>;
    };
  };
} {
  return {
    type: 'ADD_TRANSLATION' as const,
    translations,
  };
}

export function addTranslations(language: string) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    if (isExists(getState().translations[language])) return;

    const fetchLanguage = async (lang) => {
      const data = await fetch(`/api/translation/${lang}`).then((data) => data.json());
      const translations = mapTranslations({ [lang]: data })[lang];
      return {
        [lang]: translations,
      };
    };
    const translations = await fetchLanguage(language);

    if (!translations) return;

    dispatch(doAddTranslations(translations));
  };
}

export type TranslationAction =
  | ReturnType<typeof updateTranslation>
  | ReturnType<typeof doAddTranslations>;
