import { Dispatch } from 'redux';

export function headerLinksFulfilled(data: AppState['navigation']['meta'], language: string) {
  return {
    type: 'FETCH_HEADER_LINKS_FULFILLED' as const,
    language,
    data,
  };
}

function doAddNavigation(navigation: { [x: string]: { main: { items: any }; meta: any } }): {
  type: 'ADD_NAVIGATION';
  navigation: { [x: string]: { main: { items: any }; meta: any } };
} {
  return {
    type: 'ADD_NAVIGATION' as const,
    navigation,
  };
}

export function addNavigation(language: string) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    if (getState().navigation[language]) return;

    const fetchNavigation = async (lang: string) => {
      const data = await fetch(`/api/navigation/${lang}`).then((data) => data.json());
      return {
        [lang]: { main: { items: data.main }, meta: data.meta },
      };
    };

    const navigation = await fetchNavigation(language);

    dispatch(doAddNavigation(navigation));
  };
}

export type NavigationAction =
  | ReturnType<typeof headerLinksFulfilled>
  | ReturnType<typeof doAddNavigation>;
