export const TitleFormats = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;

export type TitleProps = {
  Format: (typeof TitleFormats)[keyof typeof TitleFormats];
  className?: string;
  formatClassName?: string;
  pageHeadline?: boolean;
  title?: string;
  subtitle?: string;
  hasIntroText?: boolean;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
  isSectionReference?: boolean;
};
