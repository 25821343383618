import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

// utils
import { useDispatch as usePageDispatch } from '../Page/page-hooks';
import { SnapHandler } from './snap-instance';

const snapHandler = new SnapHandler();

export function useSnap() {
  
  const store = useStore<AppState>();
  const router = useRouter();
  const pathname = usePathname();
  const dispatchPageAction = usePageDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    snapHandler.initialize(router, store, pathname, dispatch, dispatchPageAction);
  }, [router, store, pathname, dispatch, dispatchPageAction]);
}
