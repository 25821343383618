// types
import type { TitleProps } from './title.types';

// components
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { decodingContent } from 'utils/decodingContent';

/**
 * Set Title with given tag and the regarding next lower tag
 *
 * @param title Title text
 * @param subtitle  Subtitle text
 * @param format Title tag format
 * @param pageHeadline Is the it a pageHeadline or not, this will change the wrapperClass
 * @param props Can contain a className which replaces the wrapper default className
 * @returns {*}
 * @constructor
 */

export function Title({
  title,
  subtitle,
  Format,
  pageHeadline = false,
  className,
  formatClassName,
  hasIntroText,
  titlePreviewId,
  subtitlePreviewId,
  isSectionReference = false,
  ...props
}: Readonly<TitleProps>) {
  // generate classNames depending on pageHeadline
  const wrapperClass = pageHeadline ? 'grid-container c-page-headline' : 'separate-title';
  const introTextClass = hasIntroText ? 'has-introtext' : '';
  const parsedTitle = decodingContent(title);
  const parsedSubtitle = decodingContent(subtitle);

  if (!parsedTitle && !parsedSubtitle) return null;

  return (
    <div className={`title--block ${className ?? wrapperClass} ${introTextClass}`}>
      <Format {...props} className={formatClassName}>
        {parsedTitle && (
          <b>
            <InlineEdit previewId={titlePreviewId} isSectionReference={isSectionReference}>
              {parsedTitle}&nbsp;
            </InlineEdit>
          </b>
        )}
        {parsedSubtitle && (
          <InlineEdit previewId={subtitlePreviewId} isSectionReference={isSectionReference}>
            {parsedSubtitle}
          </InlineEdit>
        )}
      </Format>
    </div>
  );
}
