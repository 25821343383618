import Script from 'next/script';

import { PAGES_WHERE_COOKIE_BANNER_IS_NOT_DISPLAYED } from './pages-where-cookie-banner-not-appear';

// utils
import { useContent } from 'components/Page/page-hooks';

export function CookieBannerController() {
  const homePage = useContent();

  // user has confirmed the cookie banner by clicking on "save" or "accept all"
  const isConsentGiven =
    typeof UC_UI !== 'undefined' &&
    UC_UI?.getServicesBaseInfo()
      .flatMap((service) => service.consent.history)
      .filter(
        (service) =>
          service.action === 'onUpdateServices' || service.action === 'onAcceptAllServices',
      )
      .some((service) => service.status);

  const notShowCookieBanner = PAGES_WHERE_COOKIE_BANNER_IS_NOT_DISPLAYED.some((page) =>
    homePage?.url?.match(`.*/${page}$`),
  );

  const showCookieBanner = !notShowCookieBanner && !isConsentGiven;

  return (
    <>
      {notShowCookieBanner && (
        <Script
          id="close-cmp"
          dangerouslySetInnerHTML={{
            __html: `   
             var UC_UI_SUPPRESS_CMP_DISPLAY = true;
            if (typeof UC_UI !== 'undefined') {
              setTimeout(()=> {
              UC_UI.closeCMP();
              }, 500)
            }
          `,
          }}
        />
      )}
      {showCookieBanner && (
        <Script
          id="show-cmp"
          dangerouslySetInnerHTML={{
            __html: `  
              var UC_UI_SUPPRESS_CMP_DISPLAY = undefined;
              if (typeof UC_UI !== 'undefined') {
                setTimeout(()=> {
                  UC_UI.showFirstLayer();
                }, 500)
              }  
            `,
          }}
        />
      )}
    </>
  );
}
