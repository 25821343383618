export function getDefaultLanguage(pageLanguage?: string, homepages?: any) {
  let technicalLanguage = '';
  if (!pageLanguage) {
    // check all languages for isMasterLanguage = true
    let defaultLanguage = homepages.find((lang) => lang.isMasterLanguage);

    // if no master language is found, use the first language
    if (!defaultLanguage) {
      defaultLanguage = homepages.shift();
    }

    technicalLanguage = defaultLanguage?.language ?? '';
  } else {
    technicalLanguage = pageLanguage;
  }

  return technicalLanguage;
}
