import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

// utils
import { useContent } from './page-hooks';

export function useSplashScreen() {
  const content = useContent();
  const pageType = content?.type;
  const pathname = usePathname();
  const hash = pathname.split('#')?.[1] || '';

  return useMemo(() => {
    if (hash) {
      if ((hash.match(/#/g) || []).length > 1) {
        const hashes = decodeURIComponent(hash.split('#')?.[1]);

        // SplashScreen should only show on content- and landingpages
        return (
          hashes === '#redirect' && (pageType === 'content_page_100' || pageType === 'landingpage')
        );
      }
    }

    return false;
  }, [hash, pageType]);
}
