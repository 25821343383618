import { trackTemplate } from '../utils/tracking';
import { addSlashToPath } from 'utils/tracking/track';

/**
 * Tab click
 * @param string text
 */
export function urlChange(title: string, path: string) {
  return trackTemplate({
    event: 'genericPageview',
    data: {
      title,
      path: addSlashToPath(path),
    },
  });
}
