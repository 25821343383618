import { useState, useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { urlChange } from 'scenes/trackingActions';

export function useTrackPage(page: AppState['page'][string]) {
  const [prevPageType, setPrevPageType] = useState<string | undefined>();
  const [prevSearch, setPrevSearch] = useState<string | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string | undefined>();
  const track = useTracking();
  const { trackSearch } = useTracking2();

  const { title, name } = page.metaData ?? { title: undefined, name: undefined };
  const pageTitle = title ?? name;
  const pageType = page?.type;
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const search = searchParams.get('q');

  useEffect(() => {
    if (
      (pageTitle && documentTitle !== pageTitle) ||
      pageType !== prevPageType ||
      (pageType === 'search_results' && search && prevSearch !== search)
    ) {
      setPrevPageType(pageType);
      setDocumentTitle(pageTitle);
      setPrevSearch(search);
      track.trackEvent(urlChange(pageTitle, pathname));
    }

    if (pageType === 'search_results' && search && prevSearch !== search) {
      trackSearch({ content_type: 'geberit site', search_term: search });
    }
  }, [
    documentTitle,
    pageTitle,
    pageType,
    pathname,
    prevPageType,
    prevSearch,
    search,
    track,
    trackSearch,
  ]);
}
