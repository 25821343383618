import { useEffect } from 'react';

export function useScrollToSection(hasHistorySection: boolean) {
  useEffect(() => {
    if (hasHistorySection) return;

    const getSectionId = () => {
      const hash = window.location.hash;
      if (!hash) return undefined;

      let sectionId = hash.slice(1);
      if ((hash.match(/#/g) || []).length > 1) {
        const hashes = decodeURIComponent(hash.split('#')?.[1]);
        const splittedHash = hashes?.split('|');
        sectionId = splittedHash?.[splittedHash.length - 1];
      }

      return sectionId;
    };

    let observer: MutationObserver;

    function onElementAvailable(elementId, callback) {
      observer = new MutationObserver((_) => {
        const element = document.getElementById(elementId);
        if (element) {
          observer.disconnect();
          callback(element);
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    }

    const sectionId = getSectionId();
    if (sectionId)
      onElementAvailable(sectionId, (section) => {
        section.scrollIntoView({ block: 'start' });
      });

    return () => {
      if (observer) observer.disconnect();
    };
  }, [hasHistorySection]);
}
