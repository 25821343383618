'use client';

import { useEffect, useContext, PropsWithChildren } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { AnyAction } from 'redux';

// types
import type { scenes } from 'components/Page/scenes';

// components

// utils
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useRedirectUrl } from 'components/Page/use-redirect-url';
import { JOBS_ROUTES } from 'components/Page/job-routes';
import { useScrollToItem } from 'components/Page/use-scroll-to-item';
import { useSessionId } from 'components/Page/use-session-id';
import { useTrackPage } from 'components/Page/use-track-page';
import { useTrackScroll } from 'components/Page/use-track-scroll';
import { SessionContext, addCiamHeader } from 'components/Session/SessionContext';
import { homepageListSelector } from 'utils/selectors/homepageListSelectors';
import { getDefaultLanguage } from 'features/routing/get-default-language';
import { addTranslations } from 'components/Translation/actions';
import { addNavigation } from 'components/Navigation/actions';
import { useScrollToSection } from 'utils/hooks/use-scroll-to-section';
import { useSnap } from 'components/ContentCreator/use-snap';

type PageProps = {
  language: string;
  content: Content;
  pageType: keyof typeof scenes | 'job_search';
};

export function PageWrapper({
  children,
  language,
  content,
  pageType,
}: Readonly<PropsWithChildren<PageProps>>) {
  const router = useRouter();
  const pathname = usePathname();
  const {
    state: { gigyaWindow },
  } = useContext(SessionContext);
  const { init, role } = useCiamLogin(gigyaWindow, language);
  const dispatch = useDispatch();
  const homepageList = useSelector(homepageListSelector);

  useSnap();

  const debouncedDispatch = useDebouncedCallback((lang) => {
    dispatch({ type: 'homepageList/SET_CURRENT_LANGUAGE', language: lang });
    dispatch(addTranslations(lang) as unknown as AnyAction);
    dispatch(addNavigation(lang) as unknown as AnyAction);
  }, 100);

  useEffect(() => {
    const lang = getDefaultLanguage(content.fs_language, homepageList.item);
    const cancel = debouncedDispatch(lang);

    return cancel;
  }, [content.fs_language, homepageList.item, debouncedDispatch]);

  useEffect(() => {
    if (gigyaWindow && !role) {
      init();
    }

    if (role) {
      addCiamHeader(gigyaWindow);
    }
  }, [gigyaWindow, role, pathname, init]);

  useEffect(() => {
    if (pageType === 'job_search') {
      window.location = JOBS_ROUTES[language];
    }
  }, [language, pageType]);

  const hasHistorySection = content?.contentAreas?.content?.some(
    (contentItem) => contentItem.type === 'history',
  );

  useTrackPage(content);
  useTrackScroll();
  useScrollToItem(content);
  useSessionId();
  useScrollToSection(hasHistorySection);

  const redirectUrl = useRedirectUrl(role, pageType);

  if (redirectUrl) {
    router.replace(redirectUrl);
    return null;
  }

  if (!pageType || !language) {
    return null;
  }

  return <>{children}</>;
}
