import { Dispatch as ReactDispatch } from 'react';
import { SnapApiInterface } from './tpp';
import { PageAction } from '../Page/actions';
import { Dispatch } from 'redux';

export class SnapHandler {
  public instance: SnapApiInterface;
  public isLoading: boolean = false;

  public initialize(
    router: any,
    store: any,
    pathname: string,
    dispatch: Dispatch<PageAction>,
    dispatchPageAction: ReactDispatch<PageAction>,
  ): void {
    if (!this.instance && !this.isLoading) {
      this.isLoading = true;
      import('./tpp').then((m) => {
        this.instance = new m.default(router, store, pathname, dispatch, dispatchPageAction);
        setTimeout(() => {
          document.dispatchEvent(new CustomEvent('snap:ready'));
        }, 500);
      });
    } else if (this.instance) {
      this.instance.router = router;
      this.instance.store = store;
      this.instance.pathname = pathname;
      this.instance.dispatch = dispatch;
      this.instance.dispatchPageAction = dispatchPageAction;
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('snap:ready'));
      }, 500);
    }
  }
}
