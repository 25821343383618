export function mapTranslations(
  allTranslations: Record<string, Record<string, { value: string }>>,
) {
  return Object.entries(allTranslations).reduce(
    (accumulator, [language, translations]) => {
      accumulator[language] = {
        keys: Object.entries(translations).reduce((acc, [key, { value }]) => {
          acc[key] = value;
          return acc;
        }, {}),
        keysWithPreviewId: translations,
      };
      return accumulator;
    },
    {} as Record<
      string,
      {
        keys: Record<string, string>;
        keysWithPreviewId: Record<
          string,
          {
            value: string;
          }
        >;
      }
    >,
  );
}
