'use client';

import { DialogueProvider } from '@geberit/gdds';

// types
import type { Scene } from 'components/Page/scenes';

// components
import { IsoLangProvider } from 'scenes/MetaData';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import { CookieBannerController } from './cookie-banner-controller';

// utils
import { PageWrapper } from './page-wrapper';
import { ContentContextProvider } from 'components/ContentArea/ContentContext';
import { EditingProvider } from 'components/ContentCreator/EditingContext';
import { PrevLocationContextProvider } from 'components/Page/prev-location-context';
import { CookieConsentProvider } from 'features/cookie-consent';
import { PageContextProvider } from 'components/Page/page-context';
import { ParentProvider } from 'scenes/parent-provider';

export function Providers({
  children,
  pageType,
  metaLanguage,
  content,
}: Readonly<
  React.PropsWithChildren<{
    pageType: Scene;
    metaLanguage: string;
    content: Content;
  }>
>) {
  return (
    <ParentProvider value={{ parent: 'page', pageType }}>
      <IsoLangProvider lang={metaLanguage}>
        <SplashScreen />
        <CookieConsentProvider>
          <EditingProvider>
            <ContentContextProvider>
              <DialogueProvider>
                <PrevLocationContextProvider>
                  <PageContextProvider content={content}>
                    <PageWrapper content={content} language={metaLanguage} pageType={pageType}>
                      <CookieBannerController />
                      {children}
                    </PageWrapper>
                  </PageContextProvider>
                </PrevLocationContextProvider>
              </DialogueProvider>
            </ContentContextProvider>
          </EditingProvider>
        </CookieConsentProvider>
      </IsoLangProvider>
    </ParentProvider>
  );
}
